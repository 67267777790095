import styled, { css } from "styled-components";
import Flex from "../Flex";

const Wrap = styled.div`
  position: absolute;
  bottom: 40px;
  /* left: 50%; */
  ${(p) => {
    if (p.align === "right")
      return css`
        right: 30px;
      `;
    else if (p.align === "left")
      return css`
        left: 30px;
      `;
    else
      return css`
        left: 50%;
        transform: translate(-50%, 0);
      `;
  }}
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 920px) {
    bottom: 0px;
  }
  .dot {
    width: 70px;
    height: 4px;
    background-color: ${(props) => props.theme.color.gray300};
    transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
    margin: 0;
    margin-top: 7px;
    &.active {
      background-color: ${(props) => props.theme.color.black};
    }
  }
  .dotHover {
    cursor: pointer;
  }
  nav {
    font-size: 36px;
    margin-left: 15px;
    span {
      display: inline-block;
      padding: 5px;
      cursor: pointer;
      line-height: 0;
      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
`;

function SliderNav({ data, onSlide, selected, align }) {
  return (
    <Wrap className="slider-nav" align={align}>
      <Flex>
        {data.map((_, i) => (
          <div key={i} className="dotHover" onClick={() => onSlide(i)}>
            <div key={i} className={`dot ${selected === i ? "active" : ""}`} />
          </div>
        ))}
      </Flex>
      <nav>
        <span
          className={selected <= 0 ? "disabled" : ""}
          onClick={() => onSlide(--selected)}
        >
          &larr;
        </span>
        <span
          className={selected >= data.length - 1 ? "disabled" : ""}
          onClick={() => onSlide(++selected)}
        >
          &rarr;
        </span>
      </nav>
    </Wrap>
  );
}

export default SliderNav;
