// *** React
import React from "react";
import Approach from "./Approach/Approach";
import Values from "./Values/Values";
import WeDo from "./WeDo/WeDo";
import WeOffer from "./WeOffer/WeOffer";
import CaseStudies from "./CaseStudies/CaseStudies";
import Hero from "./Hero/Hero";
import NavHeader from "./NavHeader";
import Nav from "./Hero/Nav";
import OurClints from "./OurClints/OurClints"
import "./home.scss"
import MainTitle from "../MainTitle";
import { Link } from "react-router-dom";
import logo1 from "../../assets/stitchain.svg"
import logo2 from "../../assets/stitvest.svg"
import star from "../../assets/Star 3.svg"
import logo4 from "../../assets/formsEasyLogo.svg"
import logo5 from "../../assets/libancall3.svg"
import logo6 from "../../assets/albath.svg"
import logo7 from "../../assets/Pflege.svg"
import logo8 from "../../assets/niceMove.svg"
import logo9 from "../../assets/stitvestNew.svg"
// *** Styled Components

// *** Components

function HomeContainer() {
  const leftLogos = [
    { src: logo1,link:"https://www.stitchain.com/" },
   
   
    { src: logo4,link:"" },
    { src: logo5,link:"http://www.libancallplus.com/" },
    { src: logo2,link:"" },
    { src: logo6,link:"" },
    { src: logo7,link:"" },
    { src: logo8,link:"" },
    { src: logo9,link:"https://www.stitvest.com/" },
    { src: logo1,link:"https://www.stitchain.com/" },
   
   
    { src: logo4,link:"" },
    { src: logo5,link:"http://www.libancallplus.com/" },
    { src: logo2,link:"" },

  ];
  return <>
    <Hero />
   < div className="main">
  {/* <NavHeader/> */}

   <WeDo/>
   <div id="weOffer">
   <WeOffer name="weOffer"/>
   </div>
  
   <Values/>  
    <Approach/>
    
   
     <CaseStudies/> 
     {/* <OurClints/> */}
     <MainTitle
          fTitle="Our"
          sTitle="Partners"
          subtitle="From startups to fortune 500 companies,
          we create custom solutions that grow brands online"
         
          details="" 
        />
    </div>
    {/* <div className="logoslider" style={{marginTop:"-40px"}}>
        <div class="logos">
          <div class="logo_items">
          {/* <Row lg={leftLogos.length} md={4} xs={2} sm={3} >
            {leftLogos.map((logo) => (
         
            <Col><Link to={logo.link} target="_blank"><img style={{width:"75px",height:"75px"}} src={logo.src} /></Link> </Col>
               
            
            ))}

              </Row> *
{
leftLogos.map((logo) => (
         
         <Link className="linklogo" to={logo.link} target="_blank"><img   src={logo.src} /></Link>    ))}
          </div>
        </div>

        {/* <div class="logos">
          <div class="logo_items2">
            {leftLogos.map((logo) => (
              <>
                <img src={logo.src} />
              </>
            ))}
          </div>
          
        </div> 
      </div> */}
      <div class="sliderer">
  <div class="slide-trackck">
  {/* {leftLogos.map((logo)=>(<div class="slidede">
    <img className="slide-img" src={logo.src} alt="" />
    </div>))} */}
  <div class="slidede">
    <img className="slide-img" src={logo1} alt="" />
    </div>

    <div class="slidede">
    <img className="slide-img" src={logo2} alt="" />
    </div>
    <div class="slidede">
    <img className="slide-img" src={logo4} alt="" />
    </div><div class="slidede">
    <img className="slide-img" src={logo6} alt="" />
    </div>
    <div class="slidede">
    <img className="slide-img" src={logo7} alt="" />
    </div><div class="slidede">
    <img className="slide-img" src={logo8} alt="" />
    </div>
    <div class="slidede">
    <img className="slide-img" src={logo5} alt="" />
    </div><div class="slidede">
    <img className="slide-img" src={logo9} alt="" />
    </div>
    
  
  </div>
</div>
  
    </>
  
}

export default HomeContainer;
