import styled from "styled-components";

const Label = styled.label`
 
  color: black;
  display: block;
  margin-bottom: 5px;
  padding-left: 2px;
  text-align:left;
  font-family: Nunito;
  font-size: 15px;
  font-weight: 600;
  line-height: 32.74px;
  text-align: left;

`;

export default Label;
