import styled, { css } from "styled-components";

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-position: ${(props) => props.bgPosition};
  background-repeat: no-repeat;
  background-size: ${(props) => props.bgSize}; */
  transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1);
  opacity: ${(props) => (props.show ? "1" : "0")};
  pointer-events: ${(props) => (props.show ? "all" : "none")};

  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* ${(p) =>
    p.hasContent &&
    css`
      @media only screen and (max-width: 920px) {
        background-image: none !important;
        display: block;
      }
    `} */
`;

function SlideWrap({ data, Slide, show, slideRef }) {
  return (
    <Wrap
      show={show}
      className="slide"
    >
      {/* <SliderContent show={show} slide={slide} /> */}
      <Slide data={data} show={show} slideRef={slideRef} />
    </Wrap>
  );
}

export default SlideWrap;
