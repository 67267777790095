import { Link } from "react-router-dom";
import styled from "styled-components";
import stylesBlocks from "./core/stylesBuilder";

export default styled(Link)`
  text-decoration: underline;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  color: ${(p) => p.theme.color.primary};
  &:hover {
    text-decoration: none;
    color: ${(p) => p.theme.color[p.hover || "gray700"]};
  }
  ${stylesBlocks}
`;
