import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

import Service from "./Service";

import styled from "styled-components";
import MainTitle from "../../MainTitle";
import { Flex } from "ui";
import { animate, motion, useAnimationControls } from "framer-motion";
import "./weOffer.css"
const StyledWeOffer = styled.div`
  .main {
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .unHover {
    text-align: left;

    color: rgba(0, 0, 0, 0.7);

    width: 320px;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 700;

    cursor: pointer;

    @media screen and (max-width: 1200px) {
      font-size: 19px;
    }
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
    @media screen and (max-width: 474px) {
      font-size: 16px;
    }
  }
  .Hover {
    text-align: left;

    color: black;

    width: 320px;
    font-family: "Poppins";
    font-size: 19px;
    font-weight: 700;

   
    padding-left: 15px;
    
    cursor: pointer;
    /* transition: 0.3s ease-in 0.3s; */
    transition: all .8s ease-in-out;
    @media screen and (max-width: 1200px) {
      font-size: 19px;
    }
    @media screen and (max-width: 991px) {
      font-size: 18px;
    }
    @media screen and (max-width: 767px) {
      font-size: 17px;
    }
    @media screen and (max-width: 474px) {
      font-size: 16px;
    }
  }

  .mobile {
    display: none;
    @media screen and (min-width: 767px) {
      display: none !important;
    }

    @media screen and (max-width: 767px) {
      display: block !important;
    }
    @media screen and (max-width: 474px) {
      display: block !important;
    }
  }

  .web {
    display: block;

    @media screen and (min-width: 767px) {
      display: block !important;
    }

    @media screen and (max-width: 767px) {
      display: none !important;
    }
    @media screen and (max-width: 474px) {
      display: none !important;
    }
  }
  .colbr {
    border-right: 4px solid black;
    height: 450px;

    @media screen and (max-width: 767px) {
      border-right: 0px;
      height: fit-content;
    }
    @media screen and (max-width: 474px) {
      border-right: 0px;
      height: fit-content;
      margin-top: 0px;
      line-height: 25px;
    }
  }
  .details {
    margin-bottom: 20px;
    visibility: visible;
    opacity: 1;
    max-height: fit-content;
    transition: all 0.8s ease-out;
  }
  .undetails {
    margin-bottom: 20px;
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: all 0.8s ease-out;
  }
  .weOffer {
    margin-left: 220px;
    width: 1176px;
    @media screen and (max-width: 1200px) {
      margin-left: 100px;
      width: 1200px;
    }
    @media screen and (max-width: 991px) {
      margin-left: 50px;
      width: 800px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 40px;
    }
    @media screen and (max-width: 474px) {
      margin-left: 40px;
    }
  }



`;
const wrapperVariants = {
  hidden: {
    opacity: 0,
    x: '100vw',
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: 'spring', delay: 0.1 },
  },
  exit: {
    x: '-100vh',
    transition: { ease: 'easeInOut' },
  },
};



function WeOffer() {
  const controls = useAnimationControls();

  const [id, setID] = useState(1);
  const [key, setKey] = useState(1);
  const handleMouseOver = (e) => {
    controls.start('visible')
    switch (e.target.id) {
      case "1":
        setID(1);
        setKey(1);
        controls.start('visible')
        console.log("key", key);

        break;
      case "2":
        setID(2);
        setKey(2);
        controls.start('visible')
        console.log("key", key);
        break;
      case "3":
        setID(3);
        setKey(3);
        controls.start('visible')
        break;
      case "4":
        setID(4);
        setKey(4);
        controls.start('visible')
        break;
      case "5":
        setID(5);
        setKey(5);
        controls.start('visible')
        break;
    }
  };
  return (
    <StyledWeOffer>
      <MainTitle
        fTitle="What"
        sTitle="We Offer"
        subtitle="FULL-CYCLE SERVICES FOR YOUR NEEDS"
        details=" We provide end-to-end app and web development services at affordable rates. Also we help global brands to develop digital products from scratch."
      />
      {/* <Row xs={1} md={2} sm={2} className="g-4 weOffer">
        <Col md="4" className="colbr">
          <br></br>

          <Row>
            <div
              id="1"
              onClick={handleMouseOver}
              className={id === 1 ? "Hover" : "unHover"}
            >
              Web development
            </div>
            <div className={id === 1 ? "details mobile" : "undetails mobile"}>
              <Service id="1" />
            </div>
          </Row>
          <br />
          <Row>
            <div
              id="2"
              onClick={handleMouseOver}
              className={id === 2 ? "Hover" : "unHover"}
            >
              Mobile APPS development
            </div>
            <div className={id === 2 ? "details mobile" : "undetails mobile"}>
              <Service id="2" />
            </div>
          </Row>
          <br />
          <Row>
            <div
              id="3"
              onClick={handleMouseOver}
              className={id === 3 ? "Hover" : "unHover"}
            >
              {" "}
              Blockchain & Decentralized Applications
            </div>
            <div className={id === 3 ? "details mobile" : "undetails mobile"}>
              <Service id="3" />
            </div>
          </Row>
          <br />
          <Row>
            <div
              id="4"
              onClick={handleMouseOver}
              className={id === 4 ? "Hover" : "unHover"}
            >
              Digital transformation
            </div>
            <div className={id === 4 ? "details mobile" : "undetails mobile"}>
              <Service id="4" />
            </div>
          </Row>
          <br />
          <Row>
            <div
              id="5"
              onClick={handleMouseOver}
              className={id === 5 ? "Hover" : "unHover"}
            >
              Experience design
            </div>
            <div className={id === 5 ? "details mobile" : "undetails mobile"}>
              <Service id="5" />
            </div>
          </Row>
        </Col>

        <Col md="8">
          <div className="web">
            <Service id={key.toString()} />
          </div>
        </Col>
      </Row> */}

      <div
      className="main-offer-div1"
       
      >
        <div
        className="main-offer-div"
      
        >
          <div
            id="1"
            onClick={handleMouseOver}
            className={id === 1 ? "Hover" : "unHover"}
          >
            Web development
          </div>
          
          <div
            id="2"
            onClick={handleMouseOver}
            className={id === 2 ? "Hover" : "unHover"}
          >
            Mobile APPS development
          </div>
          <div
            id="3"
            onClick={handleMouseOver}
            className={id === 3 ? "Hover" : "unHover"}
          >
            {" "}
            Blockchain & Decentralized Applications
          </div>
          <div
            id="4"
            onClick={handleMouseOver}
            className={id === 4 ? "Hover" : "unHover"}
          >
            Digital transformation
          </div>
          <div
            id="5"
            onClick={handleMouseOver}
            className={id === 5 ? "Hover" : "unHover"}
          >
            Experience design
          </div>
        </div>
        {
          key===1?
          <div  className="webb">
          <Service id={key.toString()} />
        </div>:
         key===2?
         <div  className="webb1">
         <Service id={key.toString()} />
        </div>:
         key===3?
         <div  className="webb2">
         <Service id={key.toString()} />
        </div>
        :
        key===4?
        <div  className="webb3">
        <Service id={key.toString()} />
       </div>
         :
         key===5?
         <div  className="webb4">
         <Service id={key.toString()} />
        </div>
        :undefined
        }
       
      </div>
<div className="main-offer-div-mobile">
      <div
              id="1"
              onClick={handleMouseOver}
              className={id === 1 ? "Hovermobile" : "unHovermobile"}
            >
              Web development
            </div>
            <div className={id === 1 ? "details" : "undetails"}>
              <Service id="1" />
            </div>

            <div
              id="2"
              onClick={handleMouseOver}
              className={id === 2 ? "Hovermobile" : "unHovermobile"}
            >
              Web development
            </div>
            <div className={id === 2 ? "details" : "undetails"}>
              <Service id="2" />
            </div>
            <div
              id="3"
              onClick={handleMouseOver}
              className={id === 3 ? "Hovermobile" : "unHovermobile"}
            >
              Web development
            </div>
            <div className={id === 3 ? "details" : "undetails"}>
              <Service id="3" />
            </div>
            <div
              id="4"
              onClick={handleMouseOver}
              className={id === 4 ? "Hovermobile" : "unHovermobile"}
            >
              Web development
            </div>
            <div className={id === 4 ? "details" : "undetails"}>
              <Service id="4" />
            </div>
            <div
              id="5"
              onClick={handleMouseOver}
              className={id === 5 ? "Hovermobile" : "unHovermobile"}
            >
              Web development
            </div>
            <div className={id === 5 ? "details" : "undetails"}>
              <Service id="5" />
            </div>
            </div>
    </StyledWeOffer>
  );
}

export default WeOffer;
