import styled, { css } from "styled-components";
import stylesBlocks from "./core/stylesBuilder";
import { buildRule } from "./core/stylesBuilder";
import isPropValid from "@emotion/is-prop-valid";

const Flex = styled.div.withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  display: flex;
  justify-content: ${(p) => (p.jc ? p.jc : "center")};
  align-items: ${(p) => (p.ai ? p.ai : "center")};
  gap: ${(p) => (p.gap ? p.gap : "0")}px;
  flex-direction: ${(p) => (p.dir ? p.dir : "row")};
  /* width: 100%; */
  ${(p) => {
    if (!p.jc)
      return css`
        justify-content: center;
      `;
    if (typeof p.jc === "string")
      return css`
        justify-content: ${p.jc};
      `;
    else
      return css`
        justify-content: ${p.jc[0]};
        @media only screen and (max-width: 1200px) {
          justify-content: ${p.jc[1]};
        }
        @media only screen and (max-width: 992px) {
          justify-content: ${p.jc[2]};
        }
        @media only screen and (max-width: 768px) {
          justify-content: ${p.jc[3]};
        }
      `;
  }}
  ${(p) => {
    if (!p.ai)
      return css`
        align-items: center;
      `;
    if (typeof p.ai === "string")
      return css`
        align-items: ${p.ai};
      `;
    else
      return css`
        align-items: ${p.ai[0]};
        @media only screen and (max-width: 1200px) {
          align-items: ${p.ai[1]};
        }
        @media only screen and (max-width: 992px) {
          align-items: ${p.ai[2]};
        }
        @media only screen and (max-width: 768px) {
          align-items: ${p.ai[3]};
        }
      `;
  }}
  ${(p) => buildRule("gap", p.gap, 0)}
  ${(p) => {
    if (!p.dir)
      return css`
        flex-direction: "row";
      `;
    if (typeof p.dir === "string")
      return css`
        flex-direction: ${p.dir};
      `;
    else
      return css`
        flex-direction: ${p.dir[0]};
        @media only screen and (max-width: 1200px) {
          flex-direction: ${p.dir[1]};
        }
        @media only screen and (max-width: 992px) {
          flex-direction: ${p.dir[2]};
        }
        @media only screen and (max-width: 768px) {
          flex-direction: ${p.dir[3]};
        }
      `;
  }}
  ${(p) =>
    p.s &&
    css`
      & > :first-child {
        padding-right: ${p.s}px;
      }
    `}
  ${(p) =>
    p.grow &&
    css`
      flex-grow: ${p.grow};
    `}
  ${stylesBlocks}
`;

export default Flex;
