import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import SliderNav from "./SliderNav";
import SlideWrap from "./SlideWrap";

const SSlider = styled.div`
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 920px) {
    overflow: visible;
  }
  @media only screen and (max-width: 920px) {
    &::before,
    &::after {
      content: none;
    }
  }
`;

const Sliders = styled.div`
  position: relative;
  width: 100%;
  /* height: calc(100vh - ${(p) => p.offset}px); */
  overflow: hidden;
  /* min-height: 500px; */
  /* @media only screen and (max-width: 920px) {
    overflow: visible;
  } */
  /* @media (orientation: portrait) {
    min-height: calc(100vh - ${(p) => p.offset}px);
    height: auto;
    overflow: auto;
  } */
  @media only screen and (max-width: 992px) {
    /* min-height: 750px;
    height: calc(100vh - ${(p) => p.offset}px);
    overflow: visible; */
  }
  /* @media (orientation: landscape) {
    min-height: 750px;
    height:  calc(100vh - ${(p) => p.offset}px);
    overflow: auto;
  } */
`;

function Slider({ data, Slide, navAlign }) {
  const [selected, setSelected] = useState(0);
  const slideRef = useRef();
  const sliderRef = useRef();

  function setSliderHeight() {
    sliderRef.current.style.height = slideRef.current?.offsetHeight + 'px';
  }

  useEffect(() => {
    setSliderHeight();
    window.addEventListener("resize", setSliderHeight);
    return () => window.removeEventListener("resize", setSliderHeight);
  }, [data]);

  const gotoSlide = (i) => {
    setSelected(i);
  };

  return (
    <SSlider className="slider">
      <Sliders
        ref={sliderRef}
        className="sliders"
      >
        {data.map((slide, i) => (
          <SlideWrap
            key={i}
            show={selected === i}
            data={slide}
            Slide={Slide}
            slideRef={slideRef}
            className="slide"
          />
        ))}
      </Sliders>
      {data.length > 1 && (
        <SliderNav
          align={navAlign}
          data={data}
          onSlide={gotoSlide}
          selected={selected}
        />
      )}
    </SSlider>
  );
}

export default Slider;
