import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import img1 from "../../../assets/offer/webDev.svg";
import img2 from "../../../assets/offer/ios.svg";
import img3 from "../../../assets/offer/BlockChain.svg";
import img4 from "../../../assets/offer/digitalTrans.svg";
import img5 from "../../../assets/offer/UIUX.svg";

import styled from "styled-components";

const StyledService = styled.div`
.mainn{
       font-family:'Poppins';
       margin-top:0px;
       margin-left: 30px;
       @media screen and (max-width:474px){
        margin-left: 30px;
      }
}
ul{
       list-style:outside;
       padding:10px;
       @media screen and (max-width:474px){
        list-style: outside;
        padding:0px;
      }

}
.img{
       width:120px;
       height:120px;
       margin-left:100px; 
       margin-bottom: 10px;
       margin-top: 10px;
       /* @media screen and (max-width:474px){
        margin-top: 14px;
   
        margin-left:auto; 
        margin-right: auto;
      } */
        @media screen and (max-width:767px){
          display:none;
        }
     }
     li{
     font-size:20px;
     font-weight:600;
     color:black;
     //cursor:pointer;
     line-height:50px;
     margin-left:20px;
     @media screen and (max-width:1200px){
      
       margin-left:15px; 
       font-size:18px;
     
     }
     @media screen and (max-width:991px){
       margin-left:10px;
       font-size:16px;
     }
     @media screen and (max-width:767px){
       margin-left:5px;
       font-size:14px;
       line-height:35px;
     }
     @media screen and (max-width:474px){
       margin-left:0px;
       font-size:14px;
       line-height:30px;
     
     }
}
li::marker {
  color:    rgba(127, 245, 147, 1);
}
     
     .ldetails{
       font-size:14.2px;
       font-weight:400;
       line-height:20px;
       max-height: 0;
        transition: max-height 0.5s ease-out; 
        background: #f5f5f5;
        overflow: hidden;
     }
     .ldetails1{
       font-size:14.2px;
       font-weight:400;
       line-height:20px;
       background: #f5f5f5;
       overflow: hidden;
       max-height: 600px;; /* Adjust according to your content */
       transition: max-height 0.5s ease-in;
       @media screen and (max-width:1200px){
      
              font-size:13.2px;
            
            }
            @media screen and (max-width:991px){
              font-size:12.2px;
            }
            @media screen and (max-width:767px){
              font-size:11.2px;
            }
            @media screen and (max-width:464px){
              font-size:10.2px;
            
            }
     }

`;
function Service({ id }) {
  const [click, setClick] = useState(false);
  const [lid, setLid] = useState("");
  const details1 = [
    "HNNDES create your website or other digital solution from the ground up, tailored to fit your necessities, and serve your target audience to grow your brand online. It includes several stages: research and analysis. Research and planning.",
    "HNNDES create and build online platforms that enable companies or individuals to sell products or services. It includes designing and developing user-friendly interfaces, integrating secure payment gateways, managing product catalogs, and implementing features to enhance customers' overall shopping experience.",
    " HNNDES create a program that runs through your web browser. So that companies can exchange information with shareholders about the following services. Applications are used to communicate with customers comfortably and securely. Examples: forms, shopping carts, video streaming, social networking, games,  email.",
    "If you're sure of getting a reliable, high-quality website, HNNDES can cut the corner. Websites can be developed with Leobit to instantly analyze your product and detect small issues. Everything is digital. And rewrite legacy code if you want to leave your app for a more advanced platform.",
    "Get comprehensive, ongoing help for essential websites and online solutions. With extensive experience in website development tools and techniques, the HNNDES team can manage any website or web software.",
  ];
  const details2 = [
    "HNNDES is ready to cover the entire application life cycle. We monitor your requirements and carry out all development management tasks. You will get the most suitable and cost-effective application prototype, then we move to the development phase to receive a fully integrated, cross-platform or hybrid native application.",
    "If it turns out that you need to add new functionality, enhance security or implement measures to improve performance, your company has released an application but cannot support its operation, our mobile application development team can ensure the smooth operation of your mobile solutions through regular updates.",
    "If you fail to get a reliable, high-quality app, HNNDES can cut the corner. A Leobit mobile app developer can instantly analyze your product and detect minor issues. Everything is digital. It also rewrites legacy code if you want to migrate your app to a more advanced platform.",
    "Get comprehensive ongoing assistance with native Android or IOS applications and cross-platform solutions. With extensive experience in mobile application development tools and technologies, the HNNDES team can offer management of any mobile or web software.",
  ];
  const details3 = [
    "We leverage our knowledge of blockchain technologies to assist you on every step of launching your blockchain project, from creating a whitepaper and a promotional landing to developing the token itself and assisting in getting it listed and distributed.",
    "With the help of our vast experience in blockchain development, we will develop a platform with horizontal authority distribution to secure democratic and manipulation-resistant approach to operating an organization.",
    "Our expert software engineers will implement blockchain technologies to secure an array of services, from file transfer and storage to transaction processing and access rights distribution.",
    "We will leverage our broad knowledge of technologies and trends related to non-fungible tokens to support your business during creating and distributing your NFTs, from developing smart contracts to creating unique marketplaces.",
    "By utilizing blockchain, our team will develop self-executable digital protocols serving your needs. This way of utilizing blockchain is most suitable for supporting automated payments, crowdfunding, multi-signature wallets, auctions, and more.",
  ];
  const details4 = [
    "Our experts seamlessly integrate artificial intelligence into applications. Data analytics, recommendation algorithms, and chatbots are among the areas where AI is in demand. All of these tools can improve the automation of the customer service process and enhance predictability.",
    "Predictive analytics solutions analyze historical data to exploit it in identifying risks and opportunities and building predictive models. Utilizing machine learning for this purpose allows businesses to gain valuable insights into performance and risks facilitating data-driven decision making.",
    "Machine learning and natural language processing (NLP) enable businesses to automate processes like social media analysis or navigating the customer to the goal increasing the overall efficiency of sales and marketing activities.",
    "Artificial intelligence has already changed the way businesses operate and is only going to continue to do so in the coming years. Our company is a leading IT advisory, and with the help of our AI consulting experts, you can embrace this change and create a more efficient and successful business.",
    "HNNDES experts in developing artificial neural networks use AI-based tools to foster classification, prediction, regression, smart grid, etc., based on inspiration from biological neural networks. This can be useful for object recognition, speech recognition, text writing, and other purposes.",
  ];
  const details5 = [
    "Share your design requirements with us so our team can deeply understand your digital product goals and user personas, extract the latest UI and UX design solutions, and provide you with an optimized design solution that can deliver better conversions.",
    "Assembling research-based design assets to create highly structured and informative digital product wireframe samples, meeting your objectives for you to choose the best one creating more value to prototype your mission-critical digital product.",
    "We design websites that are easy to use, aesthetically appealing and rich in interactivity as well as create purposeful and engaging mobile app designs that make an instant connection with users through the app's high utility in terms of navigation and accessibility.",
    "Already have your website ready but want to design some of your important landing pages uniquely and more creatively? Our creative UI/UX designers can be of great help, whether it is about designing website landing pages or an entire website.",
    "Understanding the need and value for responsive design, we help businesses make designs responsive to all types of screens so that you can provide your users with the same experience across all devices.",
  ];
  const openDetail = (e) => {
    setClick(!click);
    setLid(e.target.id);
   console.log(lid,"lid")
  };
  switch (id) {
    case "1":
      return (
        <StyledService >
          <div className="mainn">
            <img className="img" src={img1} />
            <ul>
              <li >
                <div id="1" onClick={openDetail} >
                Web portals{" "}.
                  {/* <span >{lid === "1"  ? "-" : "+"}</span>
                 
                  <div  
                    className={lid === "1"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[0]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="2" onClick={openDetail}>
                E-Commerce platforms{" "}.
                  {/* <span>{lid === "2"  ? "-" : "+"}</span>
                 
                  <div
                    className={lid === "2"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[1]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="3" onClick={openDetail}>
                Business websites{" "}.
                  {/* <span>{lid === "3"  ? "-" : "+"}</span>
               
                  <div
                    className={lid === "3"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="4" onClick={openDetail}>
                Custom web app development{" "}.
                  {/* <span>{lid === "4"  ? "-" : "+"}</span>
                  <div
                    className={lid === "4"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[3]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="5" onClick={openDetail}>
                Content management websites (CMS){" "}.
                  {/* <span>{lid === "5"  ? "-" : "+"}</span>
                  <div
                    className={lid === "5"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[4]}
                  </div> */}
                </div>
              </li>
             
              <li>
                <div id="6" onClick={openDetail}>
                Software as a service {" "}.
                  {/* <span>{lid === "6"  ? "-" : "+"}</span>
                  <div
                    className={lid === "6"  ? "ldetails1" : "ldetails"}
                  >
                    {details1[4]}
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </StyledService>
      );

    case "2":
      return (
        <StyledService>
          <div className="mainn">
            <img className="img" src={img2} />
            <ul>
              <li>
                <div id="1" onClick={openDetail}>
                Android native development{" "}.
                  {/* <span>{lid === "1"  ? "-" : "+"}</span>
           
                  <div
                    className={lid === "1"  ? "ldetails1" : "ldetails"}
                  >
                    {details2[0]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="2" onClick={openDetail}>
               IOS native development{" "}.
                {/* <span>{lid === "2"  ? "-" : "+"}</span>
                  <div
                    className={lid === "2"  ? "ldetails1" : "ldetails"}
                  >
                    {details2[1]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="3" onClick={openDetail}>
                Cross-platform development (Flutter){" "}.
                  {/* <span>{lid === "3"  ? "-" : "+"}</span>
                  <div
                    className={lid === "3"  ? "ldetails1" : "ldetails"}
                  >
                    {details2[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="4" onClick={openDetail}>
                Windows Mobile development{" "}.
                  {/* <span>{lid === "4"  ? "-" : "+"}</span>
                  <div
                    className={lid === "4"  ? "ldetails1" : "ldetails"}
                  >
                    {details2[3]}
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </StyledService>
      );
    case "3":
      return (
        <StyledService>
          <div className="mainn">
            <img className="img" src={img3} />
            <ul >
              <li>
                <div id="1" onClick={openDetail}>
                 Wallet Development{" "}.
                 {/*<span>{lid === "1"  ? "-" : "+"}</span>
                  <div
                    className={lid === "1"  ? "ldetails1" : "ldetails"}
                  >
                    {details3[0]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="2" onClick={openDetail}>
                Smart contract development{" "}.
                  {/* <span>{lid === "2"  ? "-" : "+"}</span>
                  <div
                    className={lid === "2"  ? "ldetails1" : "ldetails"}
                  >
                    {details3[1]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="3" onClick={openDetail}>
                Asset tokenization{" "}.
                  {/* <span>{lid === "3"  ? "-" : "+"}</span>
                  <div
                    className={lid === "3"  ? "ldetails1" : "ldetails"}
                  >
                    {details3[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="4" onClick={openDetail}>
                DeFi  development{" "}.
                  {/* <span>{lid === "4"  ? "-" : "+"}</span>
                  <div
                    className={lid === "4"  ? "ldetails1" : "ldetails"}
                  >
                    {details3[3]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="5" onClick={openDetail}>
                NFT Marketplace development{" "}.
                  {/* <span>{lid === "5"  ? "-" : "+"}</span>
                  <div
                    className={lid === "5"  ? "ldetails1" : "ldetails"}
                  >
                    {details3[4]}
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </StyledService>
      );
    case "4":
      return (
        <StyledService>
          <div className="mainn">
            <img className="img" src={img4} />
            <ul>
              <li>
                <div id="1" onClick={openDetail}>
                Communication simplification{" "}.
                  {/* <span>{lid === "1"  ? "-" : "+"}</span>
                  <div
                    className={lid === "1"  ? "ldetails1" : "ldetails"}
                  >
                    {details4[0]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="2" onClick={openDetail}>
                Process automation{" "}.
                  {/* <span>{lid === "2"  ? "-" : "+"}</span>
                  <div
                    className={lid === "2"  ? "ldetails1" : "ldetails"}
                  >
                    {details4[1]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="3" onClick={openDetail}>
                Product improvement{" "}.
                  {/* <span>{lid === "3"  ? "-" : "+"}</span>
                  <div
                    className={lid === "3"  ? "ldetails1" : "ldetails"}
                  >
                    {details4[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="4" onClick={openDetail}>
                Workflow automation{" "}.
                  {/* <span>{lid === "4"  ? "-" : "+"}</span>
                  <div
                    className={lid === "4"  ? "ldetails1" : "ldetails"}
                  >
                    {details4[3]}
                  </div> */}
                </div>
              </li>
            
            </ul>
          </div>
        </StyledService>
      );
    case "5":
      return (
        <StyledService>
          <div className="mainn">
            <img className="img" src={img5} />
            <ul>
              <li>
                <div id="1" onClick={openDetail}>
                Brand creation{" "}.
                  {/* <span>{lid === "1"  ? "-" : "+"}</span>
                  <div
                    className={lid === "1"  ? "ldetails1" : "ldetails"}
                  >
                    {details5[0]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="2" onClick={openDetail}>
                Wireframing & Prototyping{" "}.
                  {/* <span>{lid === "2"  ? "-" : "+"}</span>
                  <div
                    className={lid === "2"  ? "ldetails1" : "ldetails"}
                  >
                    {details5[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="3" onClick={openDetail}>
                Web APPs  design{" "}.
                  {/* <span>{lid === "3"  ? "-" : "+"}</span>
                  <div
                    className={lid === "3"  ? "ldetails1" : "ldetails"}
                  >
                    {details5[2]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="4" onClick={openDetail}>
                Mobile APPs design{" "}.
                  {/* <span>{lid === "4"  ? "-" : "+"}</span>
                  <div
                    className={lid === "4"  ? "ldetails1" : "ldetails"}
                  >
                    {details5[3]}
                  </div> */}
                </div>
              </li>
              <li>
                <div id="5" onClick={openDetail}>
                Digital product design{" "}.
                  {/* <span>{lid === "5"  ? "-" : "+"}</span>
                  <div
                    className={lid === "5"  ? "ldetails1" : "ldetails"}
                  >
                    {details5[4]}
                  </div> */}
                </div>
              </li>
            </ul>
          </div>
        </StyledService>
      );
  }
}

export default Service;
